export const UFFormValidationMessage: Record<string, any> = {
  required: "Ce champ est requis",
  between: (min:number, max:number): string => `La valeur minimum est fixée à ${min} et la valeur maximum à ${max}.`,
  numberFormat: "Ce champ attend une valeur numérique",
  minLengthChar: (min: number): string =>
    `Au minimum, ${min} caractères sont attendus.`,
  minLengthNum: (min: number): string =>
    `Au minimum, ${min} chiffres sont attendus.`,
  maxLengthChar: (max: number): string =>
    `Au maximum, ${max} caractères sont attendus.`,
  maxLengthNum: (max: number): string =>
    `Au maximum, ${max} chiffres sont attendus.`,
  invalidEmail: "L'email est invalide.",
  maxValNum: (max: number): string => `La valeur maximum est fixée à ${max}.`,
  minValNum: (min: number): string => `La valeur minimum est fixée à ${min}.`,
  sizeMax: (max: string): string => `La taille doit être inférieure à ${max}.`,
  rankAlreadyTaken: "Cette position est deja prise",
  alphaNumericFormat: "Ce champ attend une valeur alpha numérique",
  usernameAlreadyTaken: "Un utilisateur avec cet username existe déjà",
  anonymousAlreadyTaken: "Un utilisateur avec ce code d'anonymat existe déjà",
  atLeast1: "Choisissez au moins un conseil ou desactivez ceux-ci"
};
